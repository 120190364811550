@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Roboto&display=swap');
@import './_modal.css';
@import './_toggle.css';
@import './_dropzone.css';
@import './_siparisCreate.css';
@import './_siparisDashboard.css';
@import './_navbar.css';
@import './_login.css';
@import './_siparisIdeaCreate.css';
@import './_inputErrorMessage.css';
@import './_smallModal.css';
@import './_siparisIdeaDashboard.css';
@import './_siparisIdeaDetail.css';
@import './_tooltip.css';
@import '_shareForm.css';
@import '_adminSiparisDashboard.css';
@import '_adminSiparisListItem.css';
@import '_adminSetStepIdea.css';
@import '_tedarikciTanimlama.css';
@import '_parametresec.css';

/*ROOT VARIABLES*/
:root {
  --color-primary: #32C5FF;
  --color-danger: #FF7782;
  --color-success: #41F1B6;
  --color-warning: #FFBB55;
  --color-white: #FFF;
  --color-info-dark: #7D8DA1;
  --color-info-light: #DCE1EB;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primarty-variant: #111E88;
  --color-dark-variant: #677483;
  --color-background: #F6F6F9;
  --color-box-shadow-hover: #bfc3ca;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
  --box-shadow-hover: 0 2rem 3rem var(--color-box-shadow-hover);
}

/*END ROOT VARIABLES*/

body {
  background-color: rgb(233, 230, 230) !important;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Roboto', sans-serif;
  color: var(--color-dark-variant);
  font-weight: 500;
}

.MainContainer {
  width: 1200px !important;
}

.ui.inverted.top.fixed.menu {
  background-color: white;
  /* background-image: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(48, 48, 48) 69%, rgb(77, 77, 77) 89%) !important; */
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}


.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(135deg,
      rgb(0, 0, 0) 0%, rgb(48, 48, 48) 69%, rgb(77, 77, 77) 89%) !important;
  height: 100vh;
}

.ui.inverted.menu {
  font-family: 'Montserrat', sans-serif !important;
  color: black;
  height: 60px;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
  color: black;
}

.ui.inverted.menu .item {
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .item:hover {
  background-color: rgb(212, 212, 212) !important;
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .active.item:hover {
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .active.item {
  background: rgba(206, 206, 206, 0.15);
  color: rgb(0, 0, 0) !important;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

.ui.table tr {
  border-top: 1px solid rgba(10, 87, 175, 0.1);
  background-color: rgb(231, 238, 248);
}

.floathright {
  float: right;
  margin: 0 0 0 .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.column {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.textInput {
  background: #F2F2F2 !important;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.green {
  padding: 8px 16px;
  background: #00667E;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #F2F2F2;

}

.button {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: block;
  background: #32C5FF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  /* width: 85px; */
}

.button:not(:last-child) {
  border-bottom: none;
  /* Prevent double borders */
}


.blue {
  background: #00667E;
}

.blue:hover {
  background: #0594b4;
}

/* Onay Butonu */
.confirm-button {
  background: #008000;
  /* Yeşil renk örneği */
}

.confirm-button:hover {
  background: #00b300;
  /* Hover durumunda farklı bir yeşil renk örneği */
}

/* Ret Butonu */
.reject-button {
  background: #FF0000;
  /* Kırmızı renk örneği */
}

.reject-button:hover {
  background: #ff4d4d;
  /* Hover durumunda farklı bir kırmızı renk örneği */
}

/* Geri Gönder Butonu */
.return-button {
  background: #FFA500;
  /* Turuncu renk örneği */
}

.return-button:hover {
  background: #ffcc66;
  /* Hover durumunda farklı bir turuncu renk örneği */
}

/* Kapat Butonu */
.close-button {
  background: #FF5733;
  /* Kırmızı renk örneği */
}

.close-button:hover {
  background: #ff814d;
  /* Hover durumunda farklı bir kırmızı renk örneği */
}

/* Tarihçe Butonu */
.history-button {
  background: #3366CC;
  /* Mavi renk örneği */
}

.history-button:hover {
  background: #4d80e6;
  /* Hover durumunda farklı bir mavi renk örneği */
}

/* Koyu Gri Buton */
.dark-gray-button {
  background: #333;
  /* Koyu gri renk örneği */
}

.dark-gray-button:hover {
  background: #555;
  /* Hover durumunda farklı bir koyu gri renk örneği */
}


.buttonPaylas {
  padding: 8px 18px;
  border: 2px solid #32C5FF;
  box-sizing: border-box;
  border-radius: 4px;
  color: #32C5FF;
  background-color: white;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.mr20 {
  margin-right: 20px;
}

.buttonPaylas:hover {
  border: 2px solid #0594b4;
  color: #0594b4;
}

.LinkBtn {
  background-color: white;
  border: none;
  color: #32C5FF;
}

input[type="text"] {
  background: #F2F2F2 ;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  text-align: 'center';
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding: 5px;
}

input[type="text"].has-error {
  border-color: rgba(153, 35, 14, 0.815) !important;
  background-color: rgb(248, 239, 238) !important;
}

input[type="text"],
input[type="password"],
textarea,
select {
  outline: none;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

textarea {
  background: #F2F2F2 !important;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding: 5px;
}

/* background: */
/* progress::-webkit-progress-bar {background-color: white; width: 100%;} */
/* progress {background-color: white;} */

/* value: */
/* progress::-webkit-progress-value {background-color: #32C5FF !important;} */
/* progress::-moz-progress-bar {background-color: #32C5FF !important;} */
/* progress {color: #32C5FF;} */

/* progress {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
height: 8px;
} */


.progress {
  margin: auto;
  padding: 1px;
  width: 100%;
  max-width: 500px;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  height: 12px;
}

.progress .progress__bar {
  height: 100%;
  width: 5%;
  border-radius: 4px;
  background: #32C5FF;
}

.cursorPointer {
  cursor: pointer;
}

.overlay {
  z-index: 50000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  /* filter: alpha(opacity=10); */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.overlayText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #F2F2F2;
  margin-top: 20px;
  text-shadow: 1px 1px 2px black;
}

.imgDisabled {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  opacity: 0.6;
  /* Microsoft Edge and Firefox 35+ */
}

.cursorAuto {
  cursor: auto;
}

.disabledGrey {
  color: #BDBDBD;
}

.cursorPointer {
  cursor: pointer;
}

.cursorNoPointer {
  cursor: default;
}


/*TABLE*/

table {
  background: var(--color-white);
  padding: var(--card-padding);
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px;
  /* table-layout: fixed; */
  /* display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap; */
  width: 100%;
}

.siparisBodyContainer table {
  width: 1200px;
  background: var(--color-white);
  width: auto;
  padding: var(--card-padding);
  text-align: center;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap;
}

/* table:hover {
  box-shadow: var(--box-shadow-hover);
} */

.siparisBodyContainer table th {
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}


.siparisBodyContainer table tbody td {
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
  color: white;
  background: #32C5FF;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
}

.siparisBodyContainer table tr td:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:nth-child(2) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 68px;
  z-index: 3;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
}

.siparisBodyContainer table tr td:nth-child(2) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 68px;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:nth-child(3) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 243px;
  z-index: 3;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
}

.siparisBodyContainer table tr td:nth-child(3) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 243px;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

/* table tr td:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
} */


/* main table tbody tr:last-child td{
  border:none;
} */

/*EOF TABLE*/
/* 
table {
  border-collapse: collapse;
  
} */
/* 
.coll-sticky {
  background: aquamarine;
  position: sticky;
  left: 0px;
}

.tabl-sticky>tfoot>tr>th {
  background: aquamarine;
  position: sticky;
  bottom: 0px;
}

.tabl-sticky>thead>tr>th {
  background: aquamarine;
  position: sticky;
  top: 0px;
}

.tabl-height {
  height: 320px;
  display: block;
  overflow: scroll;
  width: 100%;
}

.tabl-bordered>thead>tr>th,
.tabl-bordered>tfoot>tr>th,
.tabl-bordered>tbody>tr>td {
  border: 1px solid black;
}

.tabl-zebra>tbody>tr:nth-child(odd) {
  background: lightgray
} */
.Line {
  width: 100%;
  height: 2px;
  background: #E0E0E0;
}

.thinLine {
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

/* svg {
  color: red;
}

svg:hover {
  color: black;
} */

.siparisArsiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.siparisArsiv:hover {
  cursor: pointer;
  color: black;
}


.ApproverBodyContainer {

  background: var(--color-primary);
}



.BannerText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #F2F2F2;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
}

.BannerTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 48px;
  align-items: center;
  color: white;
  /*text-shadow: 2px 2px 3px black
  /* 0px 4px 8px rgba(0, 0, 0, 0.35); */
}


.siparisArsivTable table th {
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}

.siparisArsivTable table tbody td {
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

.siparisBodyContainer table thead {
  position: sticky;
  z-index: 5;
  inset-block-start: 0;
  /* "top" */
}

.image-container {
  max-width: 100%;
  /* Resim maksimum genişlikte olacak */
  max-height: 100%;
  /* Resim maksimum yükseklikte olacak, ekran boyutunu aşmaz */
  overflow: auto;
  /* Ekrandan büyük resimler için otomatik kaydırma çubuklarını göster */
}

.HistoryTable table th,
.approverList table th
 {
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}

.HistoryTable table tbody td,
.approverList table tbody td
 {
  height: 3rem;
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}

.approverFilter{
  padding: 20px;
  background-color: white;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.approverFilter>div{
  padding: 0 0.5rem;
}

.filterButton{
  margin: 50%+3px 0;
}

.statusDropdown{
  width: 300px;
}
.sDates,
.eDates{
position: relative;
}

.sDates>input,
.eDates>input{
padding: 0.1rem;
color: var(--color-dark-variant);
position: relative;
top: 30%;
margin: 1px;

}

.approverFilter>div>p{
  border-bottom: #969595 2px solid;

}

.dateArea{
  width: 10rem;
  height: 43px;
  
}

.sDates:first-child{
  margin: 30px;
}

.testDate{
  margin: 0 10px 0 0;
}



.approverList table {
  width: 1200px;
  width: auto;
  background:#32C5FF;
  padding: var(--card-padding);
  text-align: center;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap;
  
}
.approverList th,
.approverList td{
  background-color: white;
}


.stickyColumn {
  position:sticky;
  right:-1px;
  z-index:1;
  background-color:white;
}

.stickyColumn2 {
  position:sticky;
  right:68px;
  z-index:1;
  background-color:white;
}

.stickyColumn3 {
  position:sticky;
  right:0;
  background-color:white;
  bottom: 0;
  z-index: 2;
}

.ActiveInput{
  background-color: rgb(248, 248, 248) !important;
}

.DisabledInput{
  background-color: rgb(230, 230, 230) !important;
}