.modalContainer {
  padding: 0 px 0 px;
}

.ui.modal {
  width: 1000px;
}

.modalBodyContainer {
  margin: 15px 10px;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

.modalLine {
  margin-top: 5px;

  color: #F2F2F2;
  background-color: #F2F2F2;
  height: 5;
}

.modalHeader {
  display: flex;
  padding: 10px 25px 5px 15px;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.modalCloseButton {
  padding: 10px 10px;
  background-color: white;
}

.modalCloseButton:hover {
  background-color: #ebebeb;
}

.modalFooter {
  clear: both;
  margin-bottom: 20px;
}

.ui.modal>.content {
  padding: 0px;
}

.confirmModal.ui.modal>.content,
.denyConfirmModal.ui.modal>.content,
.approveConfirmModal.ui.modal>.content,
.sendBackConfirmModal.ui.modal>.content,
.historyModal.ui.modal>.content,
.detailsModal.ui.modal>.content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #fff;
}